<template lang="pug">
div(v-auto-animate)
  // Required [field]
  .email-required.mt-2.mb-6(class='')
    .flex.items-center.justify-between
      .text-sm.font-medium {{ content.title }}
      Switch(id="airplane-mode" v-model:checked='inputVisible')
    .mt-2.text-xs {{ content.desc }}
  // Require [field] to be verified
  .verified.mt-2.mb-6(class='text-[#94A3B8]')
    .flex.items-center.justify-between
      .text-sm.font-medium {{ content.verified_title }}
      .rounded-full.bg-primary.text-white(class='py-0.5 px-2 text-[10px] leading-4') Enterprise
    .mt-2.text-xs {{ content.verified_desc }}
  //white list
  .white_list.mt-2.mb-6(class='' v-if='inputVisible')
    .text-sm.font-medium Manage Whitelist
    .mt-1 Enter a domain name or email address to give access
    Input#white_list.mt-4.text-sm.transition-all(
      v-model='whiteListInput'
      name='white_list'
      placeholder='Type an email address and press enter'
      class='focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:shadow-[inset_0_0_0_1px_#8A2BE2]'
    )
  //black list
  .black_list.mt-2(class='' v-if='inputVisible')
    .text-sm.font-medium Manage Blacklist
    .mt-1 Enter a domain name or email address to deny access
    Input#black_list.mt-4.text-sm.transition-all(
      v-model='blackListInput'
      name='black_list'
      placeholder='Type an email address and press enter'
      class='focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:shadow-[inset_0_0_0_1px_#8A2BE2]'
    )
</template>

<script setup lang="ts">
import { Switch } from '@/components/ui/switch'
import { Input } from '@/components/ui/input'
const props = defineProps<{
  content: {
    title: string
    desc: string
    verified_title: string
    verified_desc: string
  }
}>()
const inputVisible = ref(false)

const whiteListInput = ref('')
const blackListInput = ref('')
</script>

<style scoped></style>
